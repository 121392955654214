














































































import Vue from 'vue';
import AppMainSchedule from '@/components/AppMainSchedule.vue';
import ScheduleDay from '@/components/ScheduleDay.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListScheduledRoute from '@/components/AppListScheduledRoute.vue';
import AppListDelivery from '@/components/AppListDelivery.vue';
import AppListItemScheduledRoute from '@/components/AppListItemScheduledRoute.vue';
import AppListItemDelivery from '@/components/AppListItemDelivery.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import SCHEDULE_APPROVE from '@/graphql/mutations/ScheduleApprove.graphql';
import { Schedule } from '@/types/schema';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'DriverSchedule',
  components: {
    AppMainSchedule,
    ScheduleDay,
    AppPanel,
    AppPanelItem,
    AppListScheduledRoute,
    AppListDelivery,
    AppListItemScheduledRoute,
    AppListItemDelivery,
    AppEmpty,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
    };
  },
  methods: {
    async approveSchedule(id: Schedule['id']) {
      if (!id) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: SCHEDULE_APPROVE,
          variables: {
            input: {
              scheduleId: id,
            },
          },
        });
        this.$router.push({ name: 'driver-dashboard' });
        this.$notify({ text: 'Schedule approved!', type: 'success' });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
});
