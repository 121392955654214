


















import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import formatTruckTrailer from '@/utils/formatTruckTrailer';
import { ScheduledRoute } from '@/types/schema';

export default Vue.extend({
  name: 'AppListScheduledRoute',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    route: {
      type: Object as () => ScheduledRoute,
      default: Object as () => ScheduledRoute,
    },
  },
  methods: {
    formatTruckTrailer,
  },
});
