












import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import { Address } from '@/types/schema';

export default Vue.extend({
  name: 'AppListItemAddress',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    address: {
      type: Object as () => Address,
      default: Object as () => Address,
    },
  },
  computed: {
    hasAddress(): boolean {
      const { address } = this;
      if (!address) return false;
      const { street, city, state, zip } = address;
      return !!(street && city && state && zip);
    },
    addressLineOne(): string {
      if (!this.hasAddress) return '';
      const { street } = this.address;
      return street;
    },
    addressLineTwo(): string {
      if (!this.hasAddress) return '';
      const { city, state, zip } = this.address;
      return `${city}, ${state} ${zip}`;
    },
    latitude(): number | null {
      if (!this.address) return null;
      return this.address.latitude as number;
    },
    longitude(): number | null {
      if (!this.address) return null;
      return this.address.longitude as number;
    },
    mapsLink(): string {
      if (!this.hasAddress) return '';
      const mapsUrl = 'https://www.google.com/maps/dir/?api=1&';
      const { addressLineOne, addressLineTwo, latitude, longitude } = this;
      let query = '';
      if (latitude && longitude) {
        query = `${latitude},${longitude}`;
      } else {
        query = encodeURI(`${addressLineOne} ${addressLineTwo}`);
      }
      return `${mapsUrl}destination=${query}`;
    },
    notes(): string {
      return (this.address && this.address.notes) || '';
    },
  },
});
