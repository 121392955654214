









import Vue from 'vue';

export default Vue.extend({
  name: 'AppCardPhoto',
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      default: '',
      required: true,
    },
    thumb: {
      type: String,
      default: '',
    },
  },
});
