












import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import AppCardPhoto from '@/components/AppCardPhoto.vue';
import { FileUpload } from '@/types/schema';

export interface Photo extends FileUpload {
  thumb?: FileUpload['variant'];
}

export default Vue.extend({
  name: 'AppListItemPhotos',
  components: {
    AppListItem,
    AppCardPhoto,
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    photos: {
      type: Array as () => Photo[],
      default: Array as () => Photo[],
    },
  },
});
