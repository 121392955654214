












import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import formatDate from '@/utils/formatDate';
import { ScheduledRoute } from '@/types/schema';

export default Vue.extend({
  name: 'AppListItemScheduledRoute',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    route: {
      type: Object as () => ScheduledRoute,
      default: Object as () => ScheduledRoute,
    },
  },
  methods: {
    formatDate,
  },
});
