import { render, staticRenderFns } from "./AppListItemAddress.vue?vue&type=template&id=6c6f8a54&"
import script from "./AppListItemAddress.vue?vue&type=script&lang=ts&"
export * from "./AppListItemAddress.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItemSubtitle,VListItemTitle})
