


















































import Vue from 'vue';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import subWeeks from 'date-fns/subWeeks';
import addWeeks from 'date-fns/addWeeks';
import { Schedule } from '@/types/schema';

const PAST_LIMIT = new Date('2019-05-01');
const FUTURE_LIMIT = addWeeks(new Date(), 6);

export default Vue.extend({
  name: 'SchedulePicker',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    schedule: {
      type: Object as () => Schedule,
      default: Object as () => Schedule,
    },
    loading: Boolean,
  },
  computed: {
    baseDate(): Date {
      const date = this.schedule.startDate || this.value;
      return date ? parseISO(date) : new Date();
    },
    previousWeek(): Date {
      return subWeeks(this.baseDate, 1);
    },
    nextWeek(): Date {
      return addWeeks(this.baseDate, 1);
    },
    isAtPastLimit(): boolean {
      return isBefore(this.previousWeek, PAST_LIMIT);
    },
    isAtFutureLimit(): boolean {
      return isAfter(this.nextWeek, FUTURE_LIMIT);
    },
    dateRange(): string {
      const { startDate, endDate } = this.schedule;
      if (!startDate || !endDate) return '';
      const formatTo = 'MMM dd';
      const start = format(parseISO(startDate), formatTo);
      const end = format(parseISO(endDate), formatTo);
      return `${start} - ${end}`;
    },
    statusText(): string {
      const { published, approved } = this.schedule;
      if (approved) return 'Approved';
      if (published) return 'Scheduled';
      return 'Not Scheduled';
    },
  },
  methods: {
    prevSchedule() {
      if (this.isAtPastLimit) return;
      this.setDate(this.previousWeek);
    },
    nextSchedule() {
      if (this.isAtFutureLimit) return;
      this.setDate(this.nextWeek);
    },
    setDate(date: Date) {
      const formatted = format(date, 'yyyy-MM-dd');
      this.$emit('input', formatted);
    },
  },
});
