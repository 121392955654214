














import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import formatDate from '@/utils/formatDate';
import formatTime from '@/utils/formatTime';
import { Delivery } from '@/types/schema';

export default Vue.extend({
  name: 'AppListItemDelivery',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    delivery: {
      type: Object as () => Delivery,
      default: Object as () => Delivery,
    },
  },
  methods: {
    formatDate,
    formatTime,
  },
});
