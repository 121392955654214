

























import Vue from 'vue';

export default Vue.extend({
  name: 'AppListItem',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    isLink(): boolean {
      const { href, to } = this.$attrs;
      return !!href || !!to;
    },
  },
});
