

























import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import SchedulePicker from '@/components/SchedulePicker.vue';
import SCHEDULE from '@/graphql/queries/Schedule.graphql';
import { Schedule, ScheduleQueryVariables } from '@/types/schema';

export default Vue.extend({
  name: 'AppMainSchedule',
  components: {
    AppMain,
    SchedulePicker,
  },
  inheritAttrs: false,
  data() {
    return {
      date: '',
      schedule: {} as Schedule,
    };
  },
  computed: {
    hasSchedule(): boolean {
      return this.schedule && !!this.schedule.id;
    },
    refetch() {
      return () => this.$apollo.queries.schedule.refetch();
    },
  },
  watch: {
    date(newValue) {
      this.$router.replace({ params: { date: newValue } });
    },
  },
  methods: {
    changeSchedule(event: Event) {
      const refPicker = this.$refs.picker as Vue & {
        prevSchedule: () => void;
        nextSchedule: () => void;
      };
      if (event.type === 'swiperight') refPicker.prevSchedule();
      if (event.type === 'swipeleft') refPicker.nextSchedule();
    },
  },
  apollo: {
    schedule: {
      query: SCHEDULE,
      variables(): ScheduleQueryVariables {
        const { date } = this.$route.params;
        return { date };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
