
































import Vue from 'vue';
import AppListItemPhotos from '@/components/AppListItemPhotos.vue';
import AppListItem from '@/components/AppListItem.vue';
import AppListItemAddress from '@/components/AppListItemAddress.vue';
import formatDate from '@/utils/formatDate';
import formatTime from '@/utils/formatTime';
import formatTruckTrailer from '@/utils/formatTruckTrailer';
import { Delivery } from '@/types/schema';

export default Vue.extend({
  name: 'AppListDelivery',
  components: {
    AppListItem,
    AppListItemAddress,
    AppListItemPhotos,
  },
  inheritAttrs: false,
  props: {
    delivery: {
      type: Object as () => Delivery,
      default: Object as () => Delivery,
    },
  },
  methods: {
    formatDate,
    formatTime,
    formatTruckTrailer,
  },
});
