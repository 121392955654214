







import Vue from 'vue';
import formatDay from '@/utils/formatDay';

export default Vue.extend({
  name: 'ScheduleDay',
  inheritAttrs: false,
  props: {
    date: {
      type: String,
      default: '',
    },
  },
  computed: {
    title(): string {
      return formatDay(this.date);
    },
  },
});
